.home-container {
  position: relative;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #00000070;
  text-align: center;
}

h1 {
  font-weight: bold;
  color: #ffffff;
  font-size: 6rem;
  text-shadow: 2px 2px 8px #00000040;
  margin: 0;
}

h3 {
  color: #fff;
  margin: 0;
  font-weight: 400;
  text-shadow: 2px 2px 5px #00000070;
}

.links {
  margin-top: 30px;
  width: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 425px) {
  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 0.8rem;
  }
}

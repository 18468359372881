body,
html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100vh;
	overflow-x: hidden;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

